import Vue from "vue"
import DatePicker from 'vue2-datepicker'
import SortedTablePlugin from "vue-sorted-table"
import Payments from '../widget-payment-create'
import Paginate from "@/components/widgets/pagination.vue";
import Customers from "@/components/widgets/customers";
import Loading from "@/components/widgets/Loading.vue"

Vue.use(SortedTablePlugin, {
  ascIcon: '<i class="mdi mdi-arrow-down"></i>',
  descIcon: '<i class="mdi mdi-arrow-up"></i>'
})
export default {
  components: {
    Customers,
    DatePicker,
    Payments,
    Paginate,
    Loading
  },
  data: function () {
    return {
      selectedRows: [],
      isCustomerPopupOpen: false,
      form: {
        document_no: "",
        document_date: "",
        remark: "",
        amount: null,
      },
      loading: false,
      customer: {},
      busy: false,
      advanced: false,
      status: null,
      createId: null,
      openedCreate: false,
      sortDesc: false,
      totalItem: 0,
      apiStatus: 'ไม่พบข้อมูล',
      doc: {
        start: null,
        end: null
      },
      duedate: {
        start: null,
        end: null
      },
      debtStatus: [
        { text: 'On hold', value: 'onhold' },
        { text: 'ค้างชำระ', value: 'overdue' },
        { text: 'ยกเลิก', value: 'cancel' },
        { text: 'ชำระแล้ว', value: 'success' }
      ],
      perPage: 6,
      sortBy: 'name',
      currentPage: 1,
      currentData: {},
      keyword: "",
      fields: [
        {
          key: 'document_no',
          label: 'เลขที่เอกสาร',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true
        },
        {
          key: 'created_at',
          label: 'วันที่เอกสาร',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true
        },
        {
          key: 'bill_customer_name',
          label: 'ชื่อลูกหนี้',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
        },
        {
          key: 'bill_seller',
          label: 'ผู้รับผิดชอบ',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
        },
        {
          key: 'total_price',
          label: 'ยอดชำระทั้งหมด',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          sortable: true,
          class: 'text-right'
        },
        {
          key: 'action',
          label: 'ชำระจริง',
          thClass: 'font-weight-bold text-xs-1/2',
          tdClass: 'text-gray-400 td-top text-xs-1/2',
          class: 'text-right'
        }
      ],
      items: []
    }
  },
  computed: {
    rows() {
      return this.items.length
    }
  },
  mounted() {
    // this.onInitData()
  },
  methods: {
    onCustomerSelected(e) {
      this.isCustomerPopupOpen = false;

      if (e.data) {
        this.customer = e.data
        this.loadUnpaidBill(e.data.id)
      }
    },
    validateInput(e) {
      let value = e;

      // Allow only digits and one dot (.)
      value = value.replace(/[^0-9.]/g, '');  // Remove anything except numbers and dot
      
      // Ensure only one dot (.) is allowed
      if (value.indexOf('.') !== value.lastIndexOf('.')) {
        value = value.slice(0, value.lastIndexOf('.'));
      }
  
      // Update the model value
      this.amount = value;
    },
    onRowSelected(items) {
      this.selectedRows = items
    },
    async loadUnpaidBill(id) {
      try {
        this.loading = true
        this.busy = true
        this.apiStatus = 'กำลังค้นหาข้อมูล..'

        const result = await Vue.prototype.$axios.get(`${Vue.prototype.$host}/api/v1/unPaidInvoices/${id}`)

        this.loading = false
        this.busy = false
        this.apiStatus = 'ไม่พบข้อมูล'

        if (result) {
          this.currentData = result.data.data
          this.items = result.data.data
          this.totalItem = result.data.data.length
        } else {
          this.items = []
          this.totalItem = 0
        }
      } catch (error) {
        this.loading = false
        this.busy = false
        this.apiStatus = 'ไม่พบข้อมูล'
        this.onExceptionHandler(error.response.data.message);
      }
    },
    async requestPayment() {
      if (!this.form.amount) {
        this.onExceptionHandler('กรุณาระบุจำนวนชำระ');
        return
      }

      if (this.selectedRows.length  === 0) {
        this.onExceptionHandler('กรุณาเลือกบิลที่ต้องการชำระ');
        return
      }

      try {
        this.loading = true
        this.busy = true

        const formData = new FormData()
        formData.append('remark', this.form.remark?? '')
        formData.append('amount', this.form.amount?? '')
        formData.append('customer_id', this.customer.id?? '')

        const ids = this.selectedRows.map(item => item.id);
        formData.append('items', JSON.stringify(ids))

        const result = await Vue.prototype.$axios.post(`${Vue.prototype.$host}/api/v1/payment`, formData)

        this.loading = false
        this.busy = false

        if (result) {
          alert("ทำเรื่องชำระสำเร็จ");
          this.$router.go(this.$router.currentRoute)
        }
      } catch (error) {
        this.loading = false
        this.busy = false
        this.apiStatus = 'ไม่พบข้อมูล'
        this.onExceptionHandler(error.response.data.message);
      }
    }
  }
}